.web3-info {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  padding: 30px 80px;
  font-size: 20px;
  > .connect-btn {
    background-color: #2f006b;
    border-radius: 100px;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
  }
  .connected-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 20px;
      height: 20px;
    }

    > * {
      > :first-child {
        font-size: 16px;
        color: #7000ff;
      }
      &:not(.isAddress) {
        margin-right: 10px;
      }
      &.isAddress {
        text-align: right;
      }
    }
  }
}
