.notification-page {
  padding: 50px 50px 20px 50px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .notification-request {
    &.notNav {
      flex: 1;
      justify-content: center;
    }
  }

  .notification-page__content {
    > :first-child {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 25px;
    }

    .loader {
      display: flex;
      justify-content: center;
      > :first-child {
        font-size: 18px;
      }
      .load-btn {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .notification-page {
    padding: 25px 25px 10px 25px;
    .notification-page__content {
      > :first-child {
        font-size: 25px;
      }
    }
  }
}
