.stake-item {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px 25px;
  margin-bottom: 50px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-weight: 500;
    .question {
      flex: 1;
      margin-right: 20px;
      font-size: 20px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .profile {
      &.mobile {
        display: none;
      }
      display: flex;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50px;
      }
      > :last-child {
        font-size: 20px;
      }
    }
  }

  .body {
    > :first-child {
      display: flex;
      > * {
        background: #7000ff;
        border-radius: 13px;
        padding: 8px 16px;
        color: #fff;
      }
      > :first-child {
        flex: 1;
        margin-right: 5%;
        > :first-child {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 18px;
          // margin-bottom: 5px;
          > :first-child {
            width: 18px;
            height: 18px;
          }
        }
        > :last-child {
          font-size: 16px;
        }
      }
      > :last-child {
        padding: 8px 24px;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }
        > :last-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
    .info {
      display: flex;
      color: #4a4a4a;
      margin: 10px 0;
      font-size: 14px;

      > :nth-child(odd) {
        margin-right: 15px;
      }
    }
    .attempt-btn {
      background: #2f006b;
      border-radius: 5px;
      color: #fff;
      padding: 7px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      cursor: pointer;

      &.isOpen {
        background: rgba(126, 126, 126, 0.77);
      }
    }
  }

  .attempt-section {
    margin-top: 40px;

    form {
      display: flex;
      justify-content: space-between;
      > * {
        border-radius: 7px;
        border: none;
        font-size: 18px;
      }
      input {
        flex: 1;
        padding: 10px;
        border-radius: 5px;
        margin-right: 5%;
        background: rgba(196, 196, 196, 0.52);
      }
      button {
        background: #2f006b;
        color: #fff;
        padding: 10px 30px;
      }
    }

    .submission-result {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;

      .result {
        display: flex;
        flex-direction: column;
        align-items: center;

        > :first-child {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          > :first-child {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        > :last-child {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .stake-item {
    margin-bottom: 25px;
    .header {
      display: block;
      margin-bottom: 20px;
      .profile {
        &.web {
          display: none;
        }
        &.mobile {
          display: flex;
          img {
            width: 25px;
            height: 25px;
          }
          > :last-child {
            font-size: 16px;
          }
        }
      }
      .question {
        font-size: 18px;
        margin: 10px 0;
      }
    }

    .body {
      > :first-child {
        flex-direction: column;
        > * {
          margin-bottom: 15px;
          padding: 15px 10px;
          border-radius: 5px;
        }
        > :last-child {
          padding: 15px 10px;
          img {
            width: 18px;
            height: 18px;
          }
          > :last-child {
            font-size: 20px;
          }
        }
        > :first-child {
          margin-right: 0;
          > :first-child {
            font-size: 16px;
            > :first-child {
              width: 16px;
              height: 16px;
            }
          }
          > :last-child {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
        > :last-child {
          font-size: 16px;
          align-items: center;
        }
      }
      .info {
        margin-top: 0;
      }
      .attempt-btn {
        font-size: 16px;
      }
    }

    .attempt-section {
      margin-top: 20px;
      form {
        > * {
          font-size: 16px;
        }
        input {
          flex: initial;
          width: 70%;
          margin-right: 5%;
        }
      }
      .submission-result {
        font-size: 16px;
        .result {
          > :first-child {
            > :first-child {
              width: 16px;
              height: 16px;
            }
          }
          > :last-child {
            font-size: 14px;
          }
        }
      }
    }
  }
}
