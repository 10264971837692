.home-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;

  .sections {
    > * {
      margin-bottom: 80px;
      font-size: 20px;
      display: flex;
      align-items: space-between;
      align-items: center;
      > * {
        flex: 1;
      }
    }

    .message-sections {
      display: flex;
      flex-direction: column;

      .__header {
        font-size: 25px;
        color: #2f006b;
        font-weight: 600;
        white-space: nowrap;
      }
      .__body {
        margin: 15px 0 10px 0;
      }
      .__button {
        background: #2f006b;
        padding: 7px 20px;
        color: #fff;
        border-radius: 7px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .side-img {
      // width: 100%;
      height: 405px;
    }

    #section1 {
      > :first-child {
        padding-left: 10%;
        margin-right: 50px;
      }
    }

    #section2 {
      #boxes {
        padding-left: 10%;
        margin-right: 50px;
        position: relative;

        .box {
          border-radius: 10px;
          padding: 24px 10px;
          text-align: center;
          > :first-child {
            margin-bottom: 10px;
          }
          > :last-child {
            font-weight: 700;
            font-size: 24px;
          }
        }

        .top-set {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 25px;
          > :first-child {
            margin-right: 50px;
          }
          > :last-child {
            margin-top: 50px;
            margin-right: 20%;
          }
        }

        > :last-child {
          margin: 0 50% 0 10%;
        }
      }

      > .message-sections {
        padding-right: 5%;
        align-items: flex-start;

        .__header {
          white-space: initial;
        }
      }
    }

    #section3,
    #section5 {
      > .message-sections {
        padding-left: 10%;
        margin-right: 50px;
        align-items: flex-start;
      }
    }

    #section5 {
      .side-img {
        height: 240px;
      }

      #live-amount {
        display: flex;

        > * {
          margin-bottom: 10px;
        }

        img {
          width: 25px;
          height: 25px;
          margin-left: 2px;
        }
      }
    }

    .powered {
      padding: 20px 3%;
      > * {
        flex: initial;
      }
      .logos {
        display: flex;
        align-items: center;
        overflow: hidden;
        > img {
          &:is(:first-child) {
            margin-left: 5%;
          }
          width: 25%;
          margin: 0 20px;
          animation: marquee 8s linear infinite;
        }
      }
    }

    @keyframes marquee {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-100%, 0);
      }
    }
  }

  .footer {
    background: linear-gradient(228.24deg, #3a07cb 3.56%, #7000ff 58.82%);
    padding: 40px 10% 10px 10%;
    // width: 100%;
    // display: flex;
    // justify-content: center;

    .inner-container {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      color: #fff;
      // width: 80%;

      .socialLinks {
        display: flex;
        align-items: center;
      }

      > :first-child {
        display: flex;
        justify-content: center;

        #first-logo {
          width: 240px !important;
          height: 70px !important;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          > :last-child {
            margin-top: -18px;
          }
        }
      }
      > * {
        #last-logo {
          width: 60px;
          height: 20px;
          margin-top: -6px;
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }

        &:not(:first-child) {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          &:not(:first-child) {
            align-items: flex-end;
          }

          &:not(:last-child) {
            > :last-child {
              align-items: center;
              > :last-child {
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                color: #2f006b;
                text-decoration: none;
                font-weight: 700;
              }
            }
          }

          > * {
            > :first-child {
              margin-bottom: 10px;
            }
            > * {
              color: #fff;
            }
          }

          > :first-child {
            > :first-child {
              font-weight: 700;
              font-size: 20px;
            }
          }

          > :last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1135px) {
  .home-page {
    .sections {
      overflow: hidden;
      display: table;
      #section1 {
        > :first-child {
          padding-left: 5%;
        }
      }
      #section2 {
        #boxes {
          padding-left: 5%;
        }
      }
      #section3,
      #section5 {
        > :first-child {
          padding-left: 5%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home-page {
    .sections {
      .side-img {
        height: 250px;
      }
      // #section1 {
      // }

      #section2 {
        #boxes {
          .top-set {
            > :last-child {
              margin-right: 0;
            }
          }
          > :last-child {
            margin-right: 0;
          }
        }
      }

      #section5 {
        .side-img {
          margin-top: 0 !important;
          margin-bottom: 20px !important;
          height: 188px;
        }
      }
    }
  }
}

@media only screen and (max-width: 814px) {
  .home-page {
    .sections {
      #section5 {
        #live-amount {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-page {
    .sections {
      > * {
        font-size: 16px;
        display: block;
        margin-bottom: 40px;
        &:not(:first-child) {
          &:not(.powered) {
            padding: 0 10% !important;
          }
        }
      }
      .message-sections {
        padding: 0 !important;
        text-align: center !important;
        align-items: center !important;
        .__header {
          font-size: 18px;
          padding: 0 10%;
        }
      }

      .side-img {
        height: 180px;
        width: 100%;
        flex: initial;
        margin-top: 20px;
      }

      #section1 {
        > :first-child {
          padding: 0 10%;
          margin-right: 0;
          > :first-child {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100px !important;

            > :last-child {
              // padding-left: 40px;
              width: initial !important;
            }
          }
          > :last-child {
            margin-top: 20px;
          }
        }
      }

      #section2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        #boxes {
          margin-right: 0;
          margin-bottom: 40px;
          padding: 0;

          .box {
            padding: 12px 10px;
            > :last-child {
              font-size: 18px;
            }
          }

          .top-set {
            > :first-child {
              margin-left: 0;
              margin-right: 25px;
            }
            :last-child {
              margin-top: 0;
            }
          }

          > :last-child {
            margin: 0 20% 0 20%;
          }
        }
      }

      #section3,
      #section5 {
        > .message-sections {
          margin-right: 0;
        }
      }

      #section4 {
        display: flex;
        flex-direction: column-reverse;
      }

      #section5 {
        display: flex;
        flex-direction: column-reverse;

        .side-img {
          margin-top: 0 !important;
          margin-bottom: 20px !important;
          height: 135px;
        }

        #live-amount {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .powered {
      > :first-child {
        text-align: center;
        font-size: 20px !important;
        margin-bottom: 10px;
      }
      .logos {
        > img {
          width: 30% !important;
          margin: 0 10px !important;
        }
      }
    }

    .footer {
      .inner-container {
        // width: 95%;

        .socialLinks {
          justify-content: space-around;
          img {
            width: 20px;
            height: 20px;
          }
        }

        > :first-child {
          #first-logo {
            width: 100px !important;
            height: 35px !important;
            filter: drop-shadow(0px 1.52667px 1.52667px rgba(0, 0, 0, 0.25));
          }
        }

        > * {
          text-align: center;

          #last-logo {
            margin-top: -4px;
            width: 47px;
            height: 16px;
          }

          #contact-header {
            margin-bottom: 0;
            font-size: 14px;
          }
          #terms-link {
            margin-bottom: 0;
          }

          #auth-section {
            #register-btn {
              padding: 5px;
            }
          }

          &:first-child {
            margin-bottom: 20px !important;
          }

          &:last-child {
            > :first-child {
              margin-bottom: 0px !important;
            }
          }
          &:not(:last-child) {
            margin-bottom: 0px;
          }

          &:not(:first-child) {
            flex-direction: column-reverse;
            align-items: center !important;
            > * {
              margin-bottom: 20px;
            }
            &:not(:first-child) {
              font-size: 12px;
              white-space: nowrap;
            }
            > :last-child {
              align-items: center;
            }
          }
        }
      }
    }
  }
}
