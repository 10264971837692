.user-wallet {
  padding: 30px 0;
  .balance-display {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    > * {
      background: #7000ff;
      border-radius: 7px;
      color: #fff;
      padding: 50px;
      text-align: center;
      flex: 1;

      &:nth-child(1) {
        margin-right: 50px;
      }

      > :first-child {
        font-size: 24px;
      }
      > :last-child {
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }

    .eth-logo {
      width: 34px;
      height: 34px;
      margin-right: 5px;
    }
  }

  .withdrawal {
    > :first-child {
      display: flex;
      align-items: center;
      font-size: 24px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid #2f006b;
      color: #2f006b;

      > :first-child {
        margin-right: 10px;
      }

      .connect-btn {
        background-color: #2f006b;
        color: #fff;
        border-radius: 100px;
        padding: 5px 20px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .info {
      margin-bottom: 10px;
      > span {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
      }
    }
    > form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 50px;
      font-size: 20px;
      > :first-child {
        display: flex;
        flex-direction: column;
        flex: 1;
        > .stake-coin {
          font-size: 14px;
          color: #999;
          display: flex;
          align-items: center;

          > img {
            width: 14px;
            height: 14px;
            margin: 0 2px 0 5px;
          }
        }
      }
      input {
        background: rgba(112, 0, 255, 0.11);
        border-radius: 7px;
        padding: 15px 30px;
        margin-right: 20px;
        border: none;
        flex: 1;
      }
      > :last-child {
        background: #2f006b;
        border-radius: 7px;
        padding: 15px 40px;
        color: #fff;
      }
    }
  }

  .history {
    > :first-child {
      font-size: 24px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid #2f006b;
      color: #2f006b;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    > :first-child {
      font-size: 18px;
    }
    .load-btn {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .user-wallet {
    .balance-display {
      > * {
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .user-wallet {
    .balance-display {
      > * {
        padding: 15px;

        &:nth-child(1) {
          margin-right: 20px;
        }
        > :first-child {
          font-size: 16px;
        }
        > :last-child {
          font-size: 20px;
          > img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .eth-logo {
        width: 20px;
        height: 20px;
      }
    }

    .withdrawal {
      > :first-child {
        font-size: 16px;
        justify-content: space-between;
        flex-wrap: wrap;

        .connect-btn {
          font-size: 12px;
          padding: 3px 10px;
        }
      }
      > form {
        flex-direction: column;
        align-items: initial;
        font-size: 16px;
        > :first-child {
          span {
            font-size: 12px;
          }
          > :last-child {
            margin-bottom: 10px;
          }
        }
        input {
          padding: 10px;
          margin-right: 0;
          width: 100%;
          margin-bottom: 0;
        }
        > :last-child {
          padding: 10px 20%;
        }
      }
    }
  }
}
