.leaderboard-page {
  margin-top: 50px;

  h1 {
    color: #7000ff;
    font-weight: bold;
  }

  .leaderboard-options {
    text-align: center;
    margin-top: 10px;

    > button {
      background-color: #6f00ffe4;
      color: #fff;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .leaderboard-info {
    color: #7000ff;
    margin-top: 40px;
    padding: 0 20px;
  }

  .leaderboard-table {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;

    > * {
      flex: 1;
      display: flex;
      flex-direction: column;

      &.position {
        .body {
          > * {
            display: flex;
            align-items: center;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
          }

          .position-number {
            &.champion {
              position: relative;
              left: 50px;
              top: -18px;
              width: 0px;
            }

            color: #3c06cd;
          }
        }
      }

      &.username {
        flex: 2;
        overflow: auto;

        .username-text {
          overflow: auto;
        }
      }

      &.points {
        .points-text {
          img {
            width: 16px;
            width: 16px;
          }

          // display: flex;
          align-items: center;
        }
      }

      .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        > * {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: center;
          margin: 10px 0;
          padding: 10px 10px;
        }
      }
    }

    .header {
      // align-items: center;
      padding: 10px;
      background: #6f00ff96;
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .leaderboard-page {
    .leaderboard-table {
      font-size: 16px;
    }
  }
}
