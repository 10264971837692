@import url("https://fonts.googleapis.com/css?family=Outfit:regular,bold,italic&subset=latin,latin-ext");
html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: Outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.navbar-container {
  background: #ffffff;

  .nav-display {
    padding: 15px 25px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;

    > * {
      margin-top: 10px;
    }

    .logo {
      width: 106px;
      height: 35px;
    }

    .nav-buttons {
      display: flex;
      align-items: center;

      .to-app-btn {
        // color: #7000ff;
        margin-right: 15px;
        text-decoration: none;
        cursor: pointer;
      }
      .notif-btn {
        color: #000;
        margin-right: 15px;
        > img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .auth-section {
      .mobile {
        display: none;
      }
    }
    .auth-buttons {
      display: flex;
      align-items: center;
      > * {
        text-decoration: none;
        font-size: 20px;
      }
      > :first-child {
        padding-right: 20px;
        color: #2f006b;
      }
      > :last-child {
        background: #2f006b;
        color: #fff;
        padding: 10px;
        border-radius: 8px;
      }
    }

    .user-image {
      width: 49px;
      height: 49px;
      border-radius: 50%;
    }
  }

  .nav-sub-info-display {
    > * {
      padding: 15px 50px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar-container {
    .nav-display {
      padding: 20px 20px;
      .logo {
        width: 100px;
        height: 33px;
      }

      .auth-section {
        .web {
          display: none;
        }
        .mobile {
          display: initial;
          > :first-child {
            cursor: pointer;
          }
          .auth-buttons {
            position: absolute;
            right: 20px;
            background-color: #fff;
            border-radius: 8px;
            padding: 10px;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .nav-sub-info-display {
      > * {
        padding: 15px 25px;
      }
    }
  }
}
