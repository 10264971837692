.profile-page {
  padding: 60px;

  > :first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -10px;

    > * {
      margin-top: 10px;
    }

    > :first-child {
      margin-right: 20px;
      > :first-child {
        font-weight: 700;
        font-size: 31px;
        white-space: nowrap;
      }
      > :last-child {
        text-decoration: none;
        font-size: 18px;
      }
    }
    .add-btn {
      background: #2f006b;
      border-radius: 11px;
      padding: 12px 40px;
      color: #fff;
      font-size: 18px;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  .sections {
    > :first-child {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid rgba(126, 126, 126, 0.1);
      margin-top: 50px;
      overflow-x: hidden;
      flex-wrap: nowrap;

      > * {
        font-weight: 500;
        font-size: 24px;
        padding-bottom: 10px;
        padding: 0 60px;
        cursor: pointer;

        &.isSelected {
          border-bottom: 2px solid #2f006b;
          color: #2f006b;
        }

        > :first-child {
          white-space: nowrap;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-page {
    padding: 25px;

    > :first-child {
      > :first-child {
        font-size: 24px;

        > :first-child {
          font-size: 25px;
        }

        > :last-child {
          font-size: 16px;
        }
      }
      > :last-child {
        font-size: 16px;
      }

      .add-btn {
        padding: 8px 20px;
        color: #fff;
        font-size: 16px;
        border-radius: 7px;
      }
    }

    .sections {
      > :first-child {
        > * {
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }
  }
}
