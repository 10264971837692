.register-page {
  padding: 50px 30px 30px 30px;
  margin: 0 auto;
  max-width: 100%;

  .header {
    text-align: center;
    line-height: 1;
    margin-bottom: 70px;

    > :first-child {
      font-size: 40px;
      font-weight: 700;
      color: #2f006b;
      margin-bottom: 10px;
    }

    > :last-child {
      font-size: 18px;
      color: #000;
    }
  }

  .web3-info {
    margin-top: 100px;
    padding: 30px 40px;
    // overflow-x: hidden;

    .connected-info {
      .isAddress {
        text-align: initial;
        overflow: hidden;
        > * {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  form {
    background: #ffffff;
    border-radius: 15px;
    padding: 30px 40px;
    margin: 50px 0 100px 0;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

    label {
      width: 100%;
      margin-bottom: 20px;
      > :first-child {
        font-weight: 500;
        font-size: 24px;
        color: #000;
        margin-bottom: 5px;

        &.password-label {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          > :last-child {
            cursor: pointer;
          }
        }
      }

      > :last-child {
        background: rgba(112, 0, 255, 0.11);
        border-radius: 10px;
        padding: 15px 20px;
        border: none;
        width: 100%;
        font-size: 20px;
      }
    }

    .confirmations {
      > * {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      input {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-right: 5px;
      }
    }

    button {
      width: 100%;
      background: #2f006b;
      border-radius: 10px;
      border: none;
      color: #fff;
      padding: 10px;
      font-size: 18px;
    }

    > :last-child {
      text-align: center;
      margin-top: 10px;
      font-weight: 400;
      font-size: 15px;

      > :last-child {
        color: #000;
        margin-left: 5px;
      }
    }
  }

  .web3ConnectModal {
    padding: 20px;
    font-size: 18px;

    .logos {
      margin-bottom: 20px;
      > :first-child {
        width: 35%;
      }
      > :last-child {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .register-page {
    padding: 50px 20px 20px 20px;

    .header {
      > :first-child {
        font-size: 25px;
      }
      > :last-child {
        font-size: 14px;
      }
    }

    .web3-info {
      margin-top: 25px;
      padding: 20px;
      font-size: 18px;

      .connect-btn {
        line-height: 1;
      }

      .connected-info {
        .isAddress {
          text-align: initial;
          overflow: hidden;
          > :last-child {
            font-size: 14px;
          }
        }
      }
    }

    form {
      padding: 20px;
      font-size: 14px;
      margin: 25px 0;
      label {
        > :first-child {
          font-size: 20px;
        }
        > :last-child {
          font-size: 16px;
          padding: 10px 15px;
        }
      }
    }
  }
}
