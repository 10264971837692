.admin-page {
  padding: 30px;
  > :first-child {
    font-size: 20px;
    color: #2f006b;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub-nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    font-size: 20px;
    border-bottom: 1px solid #e6e6e6;

    > * {
      flex: 1;
      text-align: center;
      &.selected {
        border-bottom: 1px solid #2f006b;
      }
    }
  }

  > :last-child {
    background: #ffffff;
    border-radius: 15px;
    padding: 40px;
    font-size: 20px;
    margin-top: 50px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  }

  .stake-details {
    > * {
      margin-bottom: 10px;
      > :first-child {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    color: #fff;

    > * {
      margin-top: 20px;
      padding: 10px 40px;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
    }
    > :first-child {
      background-color: #00830d;
      margin-right: 20%;
    }

    > :last-child {
      background-color: #bf0404;
    }
  }
}
@media only screen and (max-width: 768px) {
  .admin-page {
    > :last-child {
      box-shadow: none;
      padding: 0px;
    }

    .buttons {
      display: block;
      > :first-child {
        margin-right: 0px;
      }
    }
  }
}
