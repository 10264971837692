.notification-item {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      margin-right: 20px;
    }

    .date {
      font-size: 12px;
      color: #000;
    }
  }
  .message {
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 768px) {
  .notification-item {
    padding: 10px;

    .header {
      .title {
        font-size: 16px;
      }
      .date {
        font-size: 10px;
      }
      .message {
        font-size: 14px;
      }
    }
  }
}
