.transaction-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: auto;

  .transaction-item__date {
    // white-space: nowrap;
    flex: 1;
    margin-right: 20px;
    font-size: 14px;
    color: #999;
    margin-bottom: 5px;
    .transaction-item__date-text {
      font-size: 14px;
      color: #999;
    }
  }

  .transaction-item__description {
    // white-space: nowrap;
    flex: 1;
    margin-right: 20px;
    font-size: 14px;
    color: #999;
    margin-bottom: 5px;
    .transaction-item__description-text {
      font-size: 14px;
      color: #999;
    }
  }

  .transaction-item__amount {
    font-size: 14px;
    color: #999;
    margin-bottom: 5px;
    .transaction-item__amount-text {
      font-size: 14px;
      color: rgb(0, 131, 13);

      &.isDebit {
        color: #f00;
      }
    }
  }
}
