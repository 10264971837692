.payment-status-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 10%;

  font-size: 24px;
  font-weight: 700;

  color: #2f006b;
  .success {
    // green color
    color: #00830d;
  }
  .failure {
    color: #bf0404;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    flex-wrap: wrap;
    width: 50%;

    > :first-child {
      margin-right: 20px;
    }
    > * {
      cursor: pointer;
      font-size: 20px;
      // color: #2f006b;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .payment-status-page {
    .success {
      font-size: 18px;
    }
    .buttons {
      > * {
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
}
