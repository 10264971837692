.create-stake-page {
  padding: 100px 30px;
  margin: 0 auto;
  max-width: 100%;

  .header {
    font-weight: 700;
    font-size: 40px;
    color: #7000ff;
    text-align: center;
  }

  .web3-info {
  }

  form {
    background: #ffffff;
    border-radius: 15px;
    padding: 80px;
    margin-top: 50px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

    label {
      width: 100%;
      margin-bottom: 40px;
      > :first-child {
        font-size: 24px;
        color: #000;
        margin-bottom: 5px;
      }

      input,
      .inputStyle,
      textarea {
        background: none;
        border: none;
        &:not(.ignoreInputStyle) {
          background: rgba(112, 0, 255, 0.11);
          border-radius: 5px;
          width: 100%;
          font-size: 20px;
        }
        &:not(.amount-input) {
          padding: 20px 20px;
        }
      }

      .amount-input {
        input {
          flex: 1;
        }
      }
      .currency,
      .ethValue {
        padding: 20px 20px;
        font-weight: bold;
        background-color: #7000ff;
        height: 100%;
        color: #ffffff;

        &:not(.ethValue) {
          border-radius: 5px 0 0 5px;
        }
        &:not(.currency) {
          border-radius: 0 5px 5px 0;
          display: flex;
          align-items: center;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }

      .ethValue {
        &.mobile {
          display: none;
        }
      }

      &.hints-section {
        .note-section {
          margin-bottom: 20px;
          font-size: 20px;
          color: #000;
          line-height: 1;
          > :first-child {
            color: #bf0404;
            font-weight: 600;
            margin-bottom: 5px;
          }
        }

        input {
          margin-bottom: 20px;
          padding: 10px 20px;
        }
      }

      &.duration-section {
        > :first-child {
          margin-bottom: 15px;
        }
        .duration-options {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: -20px;

          > * {
            cursor: pointer;
            margin-top: 20px;
            > :first-child {
              padding: 20px 70px;
              background: rgba(112, 0, 255, 0.11);
              border-radius: 5px;
              font-size: 24px;
              text-align: center;
              margin-bottom: 5px;
            }
            > :last-child {
              text-align: center;
              font-size: 20px;
            }
            &.isMiddle {
              margin: 20px 30px 0px 30px;
            }
            &.selected {
              > :first-child {
                background: #2f006b;
                color: #fff;
                font-weight: 600;
              }
            }
          }
        }
      }

      &.returns-section {
        display: flex;
        justify-content: space-between;

        > * {
          > :first-child {
            font-size: 24px;
            color: #000;
            margin-bottom: 5px;
          }
        }

        > :first-child {
          margin-right: 10%;
        }

        .returns-label {
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(112, 0, 255, 0.11);
          font-size: 26px;
          padding: 20px 70px;
          border-radius: 5px;
          color: #00830d;
          font-weight: bold;

          > img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    .submit-section {
      margin-top: 10px;
      // padding: 0 5%;
      button {
        width: 100%;
        background: #2f006b;
        border-radius: 10px;
        border: none;
        color: #fff;
        padding: 10px;
        font-size: 18px;
        font-weight: 700;
      }

      .other-submit-btns {
        display: flex;
        justify-content: space-between;

        > * {
          margin-top: 20px;
          > :last-child {
            font-weight: initial;
            display: flex;
            align-items: center;
            justify-content: center;

            .stake-coin {
              display: inline-flex;
              align-items: center;
              margin-left: 5px;
              img {
                width: 18px;
                height: 18px;
                margin-right: 2px;
              }
            }
          }
        }
        > :first-child {
          margin-right: 10%;
        }
      }
    }
  }

  .paymentProcessModal {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    > :first-child {
      margin-bottom: 20px;
      img {
        width: 30%;
      }
    }

    .close-btn,
    .success-btn {
      cursor: pointer;
      font-weight: bold;
      &.close-btn {
        color: #bf0404;
      }
      &.success-btn {
        color: #00830d;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .create-stake-page {
    padding: 50px 0px;

    .header {
      font-size: 30px;
    }

    .web3-info {
      margin: 20px;
      margin-top: 25px;
      padding: 20px;
      font-size: 18px;

      .connect-btn {
        line-height: 1;
      }

      .connected-info {
        .isAddress {
          text-align: initial;
          overflow: hidden;
          > :last-child {
            font-size: 14px;
          }
        }
      }
    }

    form {
      padding: 30px;
      font-size: 14px;
      box-shadow: none;
      margin-top: 25px;

      label {
        > :first-child {
          font-size: 20px;
        }
        input,
        textarea {
          padding: 10px;
          font-size: 16px;
        }

        .amount-input {
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          input {
            padding: 20px 0;
          }
        }

        .currency {
          &.has-mobile {
            background: transparent;
            color: #000;
            padding-right: 10px;
          }
        }

        .ethValue {
          border-bottom-left-radius: 5px !important;
          border-top-right-radius: 0 !important;
          &.web {
            display: none;
          }
          &.mobile {
            padding: 10px 15px;
            font-size: 16px;
            display: flex;
            background: rgba(112, 0, 255, 0.05);
            color: #000;
          }
        }

        &.hints-section {
          .note-section {
            margin-bottom: 10px;
            font-size: 16px;
            > :first-child {
              font-size: 16px;
            }
          }

          input {
            margin-bottom: 10px;
            padding: 10px;
          }
        }

        &.duration-section {
          > :first-child {
            margin-bottom: 5px;
          }
          .duration-options {
            margin-top: -10px;
            display: initial;

            > * {
              margin-top: 10px;
              > :first-child {
                padding: 10px 40px;
                font-size: 20px;
              }
              > :last-child {
                font-size: 14px;
              }
              &.isMiddle {
                margin: 10px 0 0 0;
              }
            }
          }
        }

        &.returns-section {
          display: initial;
          > * {
            > :first-child {
              font-size: 20px;
            }
          }
          > :first-child {
            margin-right: 0;
          }
          .returns-label {
            margin-bottom: 10px;
            font-size: 22px;
            padding: 10px 40px;

            > img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      .submit-section {
        button {
          font-size: 16px;
          padding: 15px 10px;
        }
        .other-submit-btns {
          display: initial;
          > :first-child {
            margin-right: 0;
          }
          > * {
            > :last-child {
              .stake-coin {
                display: inline-flex;
                align-items: center;
                margin-left: 5px;
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
