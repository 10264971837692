.profile-stake-item {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 25px 30px;
  margin-bottom: 25px;

  .header {
    margin-bottom: 20px;
    position: relative;
    min-height: 40px;
    > :first-child {
      > * {
        > :first-child {
          // font-size: 18px;
          font-weight: 600;
        }
      }
    }
    > :last-child {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      > * {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
        color: #fff;
        padding: 7px 10px;
        margin-right: -30px;
        border-radius: 7px 0 0 7px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    > * {
      background: #7000ff;
      border-radius: 7px;
      padding: 18px 13px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      flex: 1;

      &:nth-child(2),
      &:nth-child(3) {
        margin-left: 40px;
      }

      > :first-child {
        font-size: 18px;
      }
      > .value {
        font-weight: 700;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }

  .middle {
    margin-bottom: 20px;
    background: #7000ff;
    border-radius: 7px;
    padding: 18px 13px;
    color: #fff;
    > * {
      > :first-child {
        font-size: 18px;
        font-weight: 700;
      }
      > :last-child {
        font-size: 20px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > :first-child {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-stake-item {
    padding: 12px 15px;

    .header {
      font-size: 14px;
      > :last-child {
        margin-top: 10px;
        position: initial;
        margin-right: 15px;
      }
    }

    .top {
      > * {
        padding: 10px;
        &:nth-child(2),
        &:nth-child(3) {
          margin-left: 20px;
        }
        > :first-child {
          font-size: 16px;
        }
        > .value {
          font-size: 20px;
          > img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }

      &.not-pending {
        > :last-child {
          display: none;
        }
      }
    }

    .middle {
      padding: 10px;
      > * {
        > :first-child {
          font-size: 16px;
          font-weight: 700;
        }
        > :last-child {
          font-size: 18px;
        }
      }
    }

    .bottom {
      > :first-child {
        display: block;
        background: #7000ff;
        border-radius: 7px;
        color: #fff;
        padding: 10px;
        > :first-child {
          font-size: 16px;
        }
        > .value {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
