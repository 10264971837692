.user-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 0;

  form,
  .disabled-form {
    legend {
      font-size: 24px;
      span {
        font-size: 16px;
      }
    }
    fieldset {
      border: 1px solid #000;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
    }
    label {
      width: 100%;
      margin-bottom: 20px;
      > :first-child {
        font-weight: 500;
        font-size: 20px;
        color: #000;
        margin-bottom: 5px;

        &.password-label {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          > :last-child {
            cursor: pointer;
          }
        }
      }

      > :last-child {
        background: rgba(112, 0, 255, 0.11);
        border-radius: 10px;
        padding: 15px 20px;
        border: none;
        width: 100%;
        font-size: 18px;
      }
    }

    button {
      width: 100%;
      background: #2f006b;
      border-radius: 10px;
      border: none;
      color: #fff;
      padding: 10px;
      font-size: 18px;
    }
  }

  .disabled-form {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .user-info {
    // padding: 20px 0;
    form,
    .disabled-form {
      legend {
        font-size: 20px;
      }
      font-size: 14px;
      label {
        > :first-child {
          font-size: 18px;
        }
        > :last-child {
          font-size: 16px;
          padding: 10px 15px;
        }
      }
    }
  }
}
