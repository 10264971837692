.error-register-page {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;

  > :first-child {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      color: red;
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 20px;
    }

    .message {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 20px;
      span {
        color: #7000ff;
      }
    }

    button {
      background: red;
      border-radius: 11px;
      color: #fff;
      padding: 12px 40px;
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 10px;
      border: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .error-register-page {
    padding: 30px;
    > :first-child {
      .title {
        font-size: 20px;
      }

      .message {
        font-size: 16px;
      }

      button {
        font-size: 14px;
      }
    }

    > :last-child {
      padding: 20px;
      font-size: 16px;
    }
  }
}
