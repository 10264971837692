.login-page {
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // background-color: #fff;
  padding: 30px;
  margin: auto;
  form {
    background: #ffffff;
    border-radius: 15px;
    padding: 30px 40px;
    margin: 100px 0;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

    .header {
      text-align: center;
      line-height: 1;
      margin-bottom: 50px;

      > :first-child {
        font-size: 40px;
        font-weight: 700;
        color: #2f006b;
        margin-bottom: 10px;
      }

      > :last-child {
        font-size: 18px;
        color: #000;
      }
    }

    label {
      width: 100%;
      margin-bottom: 20px;
      > :first-child {
        font-weight: 500;
        font-size: 24px;
        color: #000;
        margin-bottom: 5px;
      }

      > :last-child {
        background: rgba(112, 0, 255, 0.11);
        border-radius: 10px;
        padding: 15px 20px;
        border: none;
        width: 100%;
        font-size: 20px;
      }
    }

    .forgot-pass {
      margin-top: -15px;
      margin-bottom: 15px;
      > :last-child {
        color: #000;
        margin-left: 5px;
      }
    }

    button {
      width: 100%;
      background: #2f006b;
      border-radius: 10px;
      border: none;
      color: #fff;
      padding: 10px;
      font-size: 18px;
    }

    > :last-child {
      // text-align: center;
      margin-top: 10px;
      font-weight: 400;

      > :last-child {
        color: #000;
        margin-left: 5px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-page {
    padding: 20px;
    form {
      padding: 20px;
      margin: 50px 0;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
      font-size: 14px;

      .header {
        margin-bottom: 30px;

        > :first-child {
          font-size: 30px;
        }
        > :last-child {
          font-size: 14px;
        }
      }

      label {
        > :first-child {
          font-weight: 500;
          font-size: 20px;
        }

        > :last-child {
          padding: 10px 20px;
          font-size: 18px;
        }
      }
    }
  }
}
