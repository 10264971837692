.user-stakes {
  padding: 30px 0;
  .filter {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 10px;
    }
    select {
      height: 30px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 0 10px;
      outline: none;
    }
  }

  .stakes-list {
    margin-top: 20px;

    .loader {
      display: flex;
      justify-content: center;
      > :first-child {
        font-size: 18px;
      }
      .load-btn {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .user-stakes {
  }
}
