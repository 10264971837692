.feed-page {
  padding: 50px;
  > .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid #7e7e7e;

    > :first-child {
      > :first-child {
        color: #7000ff;
        font-weight: 700;
        font-size: 30px;
      }
    }

    > :last-child {
      background: #2f006b;
      border-radius: 11px;
      color: #fff;
      padding: 12px 40px;
      font-size: 18px;
      text-decoration: none;
    }
  }

  > .body {
    .body-header-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 30px 0;

      #stake-pool {
        display: flex;
        align-items: center;
        margin-right: 10px;
        > :first-child {
          display: flex;
          align-items: center;
          white-space: nowrap;
          margin-right: 10px;
        }

        > :last-child {
          font-weight: 700;
        }
      }

      .filter {
        display: flex;
        align-items: center;
        > :first-child {
          margin-right: 10px;
          white-space: nowrap;
        }
        select {
          height: 30px;
          border-radius: 5px;
          border: 1px solid #ccc;
          padding: 0 10px;
          outline: none;
          text-align: center;
        }
      }
    }

    .loader {
      display: flex;
      justify-content: center;
      > :first-child {
        font-size: 18px;
      }
      .load-btn {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .feed-page {
    padding: 25px;
    > .header {
      padding-bottom: 10px;
      > :first-child {
        > :first-child {
          font-size: 25px;
        }
        > :last-child {
          font-size: 14px;
        }
      }
      > :last-child {
        padding: 8px 20px;
        font-size: 16px;
        border-radius: 5px;
      }
    }

    > .body {
      .body-header-info {
        margin: 25px 0;
        #stake-pool {
          display: block;
          > :first-child {
            margin-right: 0;
            margin-bottom: 10px;
            justify-content: center;
            > :last-child {
              > span {
                display: none;
              }
            }
          }
        }
        .filter {
          display: block;
          text-align: right;
          &.no-pool {
            text-align: initial;
          }
          > :first-child {
            margin-right: 0;
            margin-bottom: 5px;
            text-align: center;

            > span {
              display: none;
            }
          }
        }
      }
    }
  }
}
