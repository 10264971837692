.notification-request {
  .header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    > :first-child {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &.isNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .body {
      font-size: 18px;
      color: #000;
      text-align: center;
      margin: 0 10px;
    }
    .btns {
      display: flex;
      align-items: center;
      > * {
        cursor: pointer;
      }
      > :first-child {
        margin-right: 20px;
        color: #2f006b;
        font-size: 18px;
      }
      > :last-child {
        background: #2f006b;
        color: #fff;
        padding: 10px;
        border-radius: 8px;
        font-size: 20px;
      }
    }
  }

  &.notNav {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      font-size: 30px;
      margin-bottom: 10px;
      > :first-child {
        width: 30px;
        height: 30px;
      }
    }

    .body {
      font-size: 24px;
      color: #000;
      text-align: center;
    }

    .btn {
      background: #2f006b;
      color: #fff;
      padding: 10px 30px;
      border-radius: 8px;
      font-size: 24px;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .notification-request {
    .header {
      font-size: 18px;
      > :first-child {
        width: 18px;
        height: 18px;
      }
    }

    &.isNav {
      flex-direction: column;
      justify-content: center;
      .body {
        font-size: 14px;
        margin: 10px 0;
      }
      .btns {
        > :first-child {
          font-size: 14px;
          margin-right: 10px;
        }
        > :last-child {
          font-size: 16px;
          padding: 5px 10px;
        }
      }
    }

    &.notNav {
      .header {
        font-size: 24px;
        margin-bottom: 5px;
        > :first-child {
          width: 24px;
          height: 24px;
        }
      }

      .body {
        font-size: 18px;
      }

      .btn {
        font-size: 18px;
        padding: 10px 20px;
        margin-top: 20px;
      }
    }
  }
}
